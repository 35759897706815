import "twin.macro"
import React from "react"
import Title from "../ui/Title"
import ServiceCard from "../ui/ServiceCard"

import { BsFillAspectRatioFill } from "react-icons/bs"
import { GiSpeaker } from "react-icons/gi"
import { BiBrain, BiCheckShield } from "react-icons/bi"
import { GrLinkedin, GrResume } from "react-icons/gr"
import { MdPersonalVideo } from "react-icons/md"

const OurServiceData = [
  {
    title: "Resume check up",
    icon: BiCheckShield,
    content:
      "Our website instantly analyzes your resume and gives you a brief evaluation about it.",
  },
  {
    title: "Resume revamp",
    icon: GrResume,
    content:
      "Make your resume stand apart from  the crowd by revamping it so that it is free of any errors or typos and tailored for the job you want.",
  },
  {
    title: "LinkedIn revamp",
    icon: GrLinkedin,
    content:
      "Revamp your LinkedIn profile with us so that is uniquely optimised for your goals and target audience.",
  },
  {
    title: "Interview preparation",
    icon: BiBrain,
    content:
      "Crack the interview of your dreams by mastering the art of interview. Brush up your interview skills by learning from the experts.",
  },
  {
    title: "English speaking",
    icon: GiSpeaker,
    content:
      "Still confused about which career to choose? Get career guidance and counseling from experts and choose your ideal career.",
  },
  {
    title: "Personality Development",
    icon: MdPersonalVideo,
    content:
      "Master the art of corporate dressing, public speaking, body language and answering spontaneously with finesse.",
  },
]

const OurServiceSection = () => {
  return (
    <div tw="p-5 mx-auto max-w-7xl space-y-10 min-h-screen grid place-content-center">
      <div tw="mx-auto text-center">
        <Title>Our Services</Title>
      </div>

      <div tw="grid md:grid-cols-3 gap-5  md:gap-10">
        {OurServiceData.map((item, index) => (
          <ServiceCard {...item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default OurServiceSection
