import "twin.macro"
import React from "react"
import Layout from "../components/layout"
import OurServiceSection from "../components/home/OurServiceSection"

const Services = () => {
  return (
    <Layout>
      <OurServiceSection />
    </Layout>
  )
}

export default Services
