import "twin.macro"
import React, { FC } from "react"
import { IconType } from "react-icons/lib"

const ServiceCard: FC<{ title: string; content: string; icon?: IconType }> = ({
  title,
  content,
  icon,
}) => {
  const Icon = icon
  return (
    <div tw="relative h-full w-full ml-0 mr-0 sm:mr-10">
      <span tw="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-300 rounded-lg"></span>
      <div tw="relative p-5 bg-white border-2 h-full border-yellow-300 rounded-lg">
        <div tw="flex items-center -mt-1 space-x-2">
          {Icon && (
            <div tw="text-2xl text-yellow-300">
              {" "}
              <Icon />
            </div>
          )}
          <h3 tw="my-2 text-lg font-bold text-gray-900">{title}</h3>
        </div>

        <p tw="mb-2 text-gray-600">{content}</p>
      </div>
    </div>
  )
}

export default ServiceCard
