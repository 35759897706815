import "twin.macro"
import React from "react"

const Title: React.FC = ({ children }) => {
  return (
    <div tw="inline">
      <p tw="relative  items-center self-start inline-block w-auto  text-2xl md:text-4xl font-black">
        <span tw="absolute inline-block w-full h-4 mt-3 -ml-2 bg-yellow-300"></span>
        <span tw="relative">{children}</span>
      </p>
    </div>
  )
}

export default Title
